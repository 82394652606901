.about-container {
  padding: 1rem;
  flex-direction: column;
}

.team-members-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.team-subheading {
  width: 100%;
  text-align: center;
  margin-bottom: 3rem;
}

.team-member {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  max-width: 15rem;
  padding: 1rem;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: .25rem;
  margin: 1rem;
}

.member-picture {
  width: 10rem;
  height: 10rem;
  border-radius: 1000rem;
  overflow: hidden;
}

.about-section {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: .25rem;
  margin: 1rem;
  padding: 1rem;
}
