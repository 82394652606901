.map-container {
  width: 100%;
  height: 100%;
}

.stacked-container {
  position: absolute;
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
}

.settings-container {
  width: 20rem;
  max-height: 60rem;
  background-color: hsl(0, 0%, 96%, 85%);
  border-radius: 0.25rem;
  padding: 1rem 1rem;
  margin-bottom: 0.5rem;
}

.selectors-container {
  overflow:hidden;
  max-height: 30rem;
  transition: all 0.5s ease;
}

.map-info-container {
  max-width: 20rem;
  background-color: hsl(0, 0%, 96%, 85%);
  border-radius: 0.25rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
}

.map-info {
  transition: all 0.5s ease;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-height: 25vh;
}

.data-container {
}

.data-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hide-button {
  cursor: pointer;
  font-size: 1.3rem;
  transition: all 0.5s ease;
}

.input-slider-container {
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-column: 1/3;
  max-height: 15rem;
}

.rotate {
  transform: rotate(180deg);
}

.legend-info-container {
  overflow:hidden;
  max-height: 30rem;
  transition: all 0.5s ease;
}

.closed {
  max-height: 0;
}

.top-container {
  display: flex;
  flex-direction: column;
}

.legend-container {
  max-width: 20rem;
  min-width: 10rem;
  background-color: hsl(0, 0%, 96%, 85%);
  border-radius: 0.25rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 5rem;
  right: 1rem;
}

.legend-item {
  display: flex;
  justify-content: space-between;
  margin: .5rem 0;
  align-items: center;
}

.legend-circle {
  border-radius: 50%;
  border: 1px solid black;
  margin-right: 1rem;
}

/* these are bad names but im lazy and these should really be custom vars*/
.blue {
  background-color: rgb(103,169,207);
  width:1.25rem;
  height:1.25rem;
}

.grey {
  background-color: rgb(208,209,230);
  width:1rem;
  height:1rem;
}

.green {
  background-color: rgb(1,108,89);
  width: 1.5rem;
  height: 1.5rem;
}

.black {
  background-color: black;
  width: 1.5rem;
  height: 1.5rem;
  border-color: white;
}

.legend-square {
  border: 1px solid black;
  margin-right: 1rem;
  width:2rem;
  height:1rem;
}

.grade1 {
  background-color: #c6dbef;
}

.grade2 {
  background-color: #9ecae1;
}

.grade3 {
  background-color: #6baed6;
}

.grade4 {
  background-color: #4292c6;
}

.grade5 {
  background-color: #2171b5;
}

.grade6 {
  background-color: #084594;
}

.legend-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.legend-container {
  transition: all 0.3s ease;
}

