:root {
  --color-primary: hsl(208, 53%, 41%, 1);
  --color-primary-light: hsl(208, 53%, 41%, 0.5);
  --navbar-height: 3rem;
  --page-height: calc(100vh - var(--navbar-height));
  --sidebar-width: max(20rem, 25%);
}

* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "Open Sans", sans-serif;
}

#root {
  width: 100%;
  height: 100%;
}

.btn {
  cursor: pointer;
}

.page-container {
  width: 100%;
  height: calc(100vh - 3rem);
  height: var(--page-height);
  display: flex;
}

a {
  text-decoration: none;
}

nav {
  height: var(--navbar-height);
  background-color: var(--color-primary);
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

.nav-group {
  display: flex;
  align-items: center;
}

.fifa-logo {
  height: 1.5rem;
  margin-left: 1rem;
}

.nav-btn {
  display: grid;
  place-items: center;
  height: 2rem;
  color: white;
}

.about {
  font-weight: 600;
}

.map-container {
  width: 100%;
  height: 100%;
}

.stacked-container {
  position: absolute;
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
}

.settings-container {
  width: 20rem;
  max-height: 60rem;
  background-color: hsl(0, 0%, 96%, 85%);
  border-radius: 0.25rem;
  padding: 1rem 1rem;
  margin-bottom: 0.5rem;
}

.selectors-container {
  overflow:hidden;
  max-height: 30rem;
  transition: all 0.5s ease;
}

.map-info-container {
  max-width: 20rem;
  background-color: hsl(0, 0%, 96%, 85%);
  border-radius: 0.25rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
}

.map-info {
  transition: all 0.5s ease;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-height: 25vh;
}

.data-container {
}

.data-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hide-button {
  cursor: pointer;
  font-size: 1.3rem;
  transition: all 0.5s ease;
}

.input-slider-container {
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-column: 1/3;
  max-height: 15rem;
}

.rotate {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.legend-info-container {
  overflow:hidden;
  max-height: 30rem;
  transition: all 0.5s ease;
}

.closed {
  max-height: 0;
}

.top-container {
  display: flex;
  flex-direction: column;
}

.legend-container {
  max-width: 20rem;
  min-width: 10rem;
  background-color: hsl(0, 0%, 96%, 85%);
  border-radius: 0.25rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 5rem;
  right: 1rem;
}

.legend-item {
  display: flex;
  justify-content: space-between;
  margin: .5rem 0;
  align-items: center;
}

.legend-circle {
  border-radius: 50%;
  border: 1px solid black;
  margin-right: 1rem;
}

/* these are bad names but im lazy and these should really be custom vars*/
.blue {
  background-color: rgb(103,169,207);
  width:1.25rem;
  height:1.25rem;
}

.grey {
  background-color: rgb(208,209,230);
  width:1rem;
  height:1rem;
}

.green {
  background-color: rgb(1,108,89);
  width: 1.5rem;
  height: 1.5rem;
}

.black {
  background-color: black;
  width: 1.5rem;
  height: 1.5rem;
  border-color: white;
}

.legend-square {
  border: 1px solid black;
  margin-right: 1rem;
  width:2rem;
  height:1rem;
}

.grade1 {
  background-color: #c6dbef;
}

.grade2 {
  background-color: #9ecae1;
}

.grade3 {
  background-color: #6baed6;
}

.grade4 {
  background-color: #4292c6;
}

.grade5 {
  background-color: #2171b5;
}

.grade6 {
  background-color: #084594;
}

.legend-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.legend-container {
  transition: all 0.3s ease;
}


.selectors {
  display: grid;
  border: 1px solid black;
  border-radius: 5px;
  place-items: center;
}

.selector-container {
}

.selector {
  cursor: pointer;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0.5rem;
  background-color: var(--color-primary-light);
  color: white;
  transition: all 0.6s ease;
  display: grid;
  place-items: center;
}

.selector:not(:last-child) {
  border-right: 1px solid black;
}

.selected {
  background-color: var(--color-primary);
}


#map-slider {
  width: 100%;
}

.input-label {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.divider {
  border-top: 1px solid black;
  margin: 2rem 0;
}

.page-content {
  height: 100%;
  width: calc(100% - var(--sidebar-width));
  display: flex;
  flex-direction: column;
  align-items: center;
}

.specificity-selector {
  display: flex;
  width: 50%;
  justify-content: space-between;
  padding: 1rem;
}

.about-container {
  padding: 1rem;
  flex-direction: column;
}

.team-members-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.team-subheading {
  width: 100%;
  text-align: center;
  margin-bottom: 3rem;
}

.team-member {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  max-width: 15rem;
  padding: 1rem;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: .25rem;
  margin: 1rem;
}

.member-picture {
  width: 10rem;
  height: 10rem;
  border-radius: 1000rem;
  overflow: hidden;
}

.about-section {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: .25rem;
  margin: 1rem;
  padding: 1rem;
}

