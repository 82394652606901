.selectors {
  display: grid;
  border: 1px solid black;
  border-radius: 5px;
  place-items: center;
}

.selector-container {
}

.selector {
  cursor: pointer;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0.5rem;
  background-color: var(--color-primary-light);
  color: white;
  transition: all 0.6s ease;
  display: grid;
  place-items: center;
}

.selector:not(:last-child) {
  border-right: 1px solid black;
}

.selected {
  background-color: var(--color-primary);
}


#map-slider {
  width: 100%;
}

.input-label {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.divider {
  border-top: 1px solid black;
  margin: 2rem 0;
}
